import { Component, ElementRef, Renderer2 } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription, forkJoin, map, of, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FooldalService } from 'src/app/services/fooldal.service';
import { HtmlconvertService } from 'src/app/services/htmlconvert.service';
import { SharedService } from 'src/app/services/shared.service';


@Component({
  selector: 'app-elofizetes',
  templateUrl: './elofizetes.component.html',
  styleUrls: ['./elofizetes.component.css']
})
export class ElofizetesComponent {
  courseDetails: any[] = []; //feliratkozott
  courseDetailsExpires: any[] = [];
  courseNonEnrollmentDetails: any[] = [];//nem feliratkozott
  courseNonEnrollmentsDetailsExpires: any[] = [];
  vidCourseNonEnrollmentsDetailsExpires: any[] = [];
  courseNonEnrollmentsDetailsExpiresProm: any[] = [];
  vidCourseNonEnrollmentsDetailsExpiresProm: any[] = [];
  hasProm: boolean = false;
  promRole: any[] = [];
  course: any[] = [];
  courseCommercie: any[] = [];
  products: any[] = [];
  private loggedInUserSubscription?: Subscription;
  loggedUser: any;
  percentage: number = 0;
  promotionObject: any = {};
  promotionsArray: any[] = [];
  postDataProducts: any = {};
  active: boolean = false;
  productDatas: any = {};
  cuoponDatas: any = {};
  licensProduct: any[] = [];
  productsForLicens: any[] = [];
  productsVidForLicens: any[] = [];

  showModal: boolean = false;
  modalTitle: string = '';
  modalContent: string = '';
  platform: string = '';
  modalActionTitle: string = '';
  modalCancelTitle: string = '';
  modalData: any;

  public cuoponIdProduct: string = "";

  coursePreviews: any[] = [];

  constructor(private fooldalService: FooldalService, private sharedService: SharedService,
    private htmlconvertService: HtmlconvertService, private authService: AuthService,
    private router: Router, private renderer: Renderer2) {
      this.sharedService.spinner$.next(true);
  }

  addCart(type: any, id: any) {
    if(!localStorage.getItem('login')) {
      this.router.navigate(['/signin'], { queryParams: { from: 'elofizetes' } });
    }
    this.postDataProducts = {
      data: [
        {
          type: type,
          id: id,
          meta: {
            quantity: 1,
            combine: true,
          },
        },
      ],
    };
    const objProf = {
      name: '',
    };
    const objBilling = {
      country_code: '',
      locality: '',
      postal_code: '',
      address_line1: '',
      address_line2: '',
      given_name: '',
      family_name: '',
    };
    this.fooldalService.addItemToCart(this.postDataProducts).subscribe((p:any) => {
      const value = p.data[0];
            const obj = {
              title: '',
              price: '',
            };
            obj.title = value.attributes.title;
            obj.price = value.attributes.total_price.formatted;
            localStorage.setItem('product', JSON.stringify(obj));

            this.authService.getCurrentUser().subscribe((user) => {
              const vv = user.data[0];
                    objProf.name = vv.attributes.name;

                    if (localStorage.getItem('login')) {
                      var loginData = JSON.parse(localStorage.getItem('login') || '');

                      if (loginData && loginData.current_user && loginData.current_user.name) {
                        var name = loginData.current_user.name;
                      }
                    }

                    if (name === objProf.name) {
                      var userId = vv.id;
                      this.fooldalService.getProfileCustomer().subscribe((profile: any) => {
                        const v = profile.data[0];
                        objBilling.country_code = v.attributes.address.country_code;
                        objBilling.locality = v.attributes.address.locality;
                        objBilling.postal_code = v.attributes.address.postal_code;
                        objBilling.address_line1 = v.attributes.address.address_line1;
                        objBilling.address_line2 = v.attributes.address.address_line2;
                        objBilling.given_name = v.attributes.address.given_name;
                        objBilling.family_name = v.attributes.address.family_name;
                        this.productDatas = {
                          "data": {
                            "type": value.relationships.order_id.data.type,
                            "id": value.relationships.order_id.data.id,
                            "attributes": {
                              "billing_information": {
                                "address": {
                                  "country_code": objBilling.country_code,
                                  "locality": objBilling.locality,
                                  "postal_code": objBilling.postal_code,
                                  "address_line1": objBilling.address_line1,
                                  "address_line2": objBilling.address_line2,
                                  "given_name": objBilling.given_name,
                                  "family_name": objBilling.family_name,
                                },
                                "tax_number": {
                                  "type": null,
                                  "value": null
                                }
                              }
                            },
                            "relationships": {
                              "uid": {
                                "data": {
                                  "type": "user--user",
                                  "id": userId
                                }
                              }
                            }
                          }
                        };
                        var orderId = value.relationships.order_id.data.id;
                        this.fooldalService.addProductWithCart(this.productDatas, orderId).subscribe((p) => {
                          for (const [keyP, valueP] of Object.entries(p)) {
                            if (keyP === 'data') {
                              for (let k in valueP) {
                                localStorage.setItem('productId', valueP.id.toString());
                              }
                            }
                          }
                          if (localStorage.getItem('login')) {
                            this.router.navigate(['/fizetes']);
                          } else {
                            this.router.navigate(['/signin'], { queryParams: { from: 'elofizetes' } });
                          }
                        });
                      });
                    } else {
                      var userId2 = localStorage.getItem('user_id');
                      if (userId2 !== null) {
                        var userIdIfNotLog = userId2.replace(/"/g, '');
                      } else {
                      }
                      this.fooldalService.getProfileCustomer().subscribe((profile) => {
                        for (const [k, v] of Object.entries(profile)) {
                          if (k === 'data') {
                            for (let j in v) {
                              if (userIdIfNotLog === v[j].relationships.uid.data.id) {
                                objBilling.country_code = v[j].attributes.address.country_code;
                                objBilling.locality = v[j].attributes.address.locality;
                                objBilling.postal_code = v[j].attributes.address.postal_code;
                                objBilling.address_line1 = v[j].attributes.address.address_line1;
                                objBilling.address_line2 = v[j].attributes.address.address_line2;
                                objBilling.given_name = v[j].attributes.address.given_name;
                                objBilling.family_name = v[j].attributes.address.family_name;
                              }
                            }
                          }
                        }
                        this.productDatas = {
                          "data": {
                            "type": value.relationships.order_id.data.type,
                            "id": value.relationships.order_id.data.id,
                            "attributes": {
                              "billing_information": {
                                "address": {
                                  "country_code": objBilling.country_code,
                                  "locality": objBilling.locality,
                                  "postal_code": objBilling.postal_code,
                                  "address_line1": objBilling.address_line1,
                                  "address_line2": objBilling.address_line2,
                                  "given_name": objBilling.given_name,
                                  "family_name": objBilling.family_name,
                                },
                                "tax_number": {
                                  "type": null,
                                  "value": null
                                }
                              }
                            },
                            "relationships": {
                              "uid": {
                                "data": {
                                  "type": "user--user",
                                  "id": userIdIfNotLog
                                }
                              }
                            }
                          }
                        };
                        var orderId = value.relationships.order_id.data.id;
                        this.fooldalService.addProductWithCart(this.productDatas, orderId).subscribe((p) => {
                          for (const [keyP, valueP] of Object.entries(p)) {
                            if (keyP === 'data') {
                              for (let k in valueP) {
                                localStorage.setItem('productId', valueP.id.toString());
                              }
                            }
                          }
                        });
                        if (localStorage.getItem('login')) {
                          this.router.navigate(['/fizetes']);
                        } else {
                          this.router.navigate(['/signin'], { queryParams: { from: 'elofizetes' } });
                        }
                      });
                    }
            });

    });
  }

  isCourseActive(sku: string): boolean {
    return this.licensProduct.some(item => item.sku === sku);
  }

  cancel(licenseUuid: string) {
    forkJoin([this.fooldalService.cancelSubscription(licenseUuid), this.fooldalService.cancelLicense(licenseUuid)]).subscribe(() => {
      window.location.reload();
    });
  }

  ngOnInit() {

    this.loggedUser = this.authService.isAuthenticated();


    //active licenszzek
    this.fooldalService.enrolledCourseLicens().subscribe(s => {
        for (const [key, value] of Object.entries(s)) {
          if (key === 'data') {
            for (let i in value) {
              if (value[i].state === 'active') {
                this.active = true;
                const obj = {
                  title: '',
                  uuid: '',
                  videostore: false,
                  end_date: '',
                  desc: '' as SafeHtml,
                  isTextOverflow: true,
                  link: '',
                  cancellable: false,
                  licenseUuid: '',
                  registered_card: '',
                };
                if (value[i].product_variation.product_id?.type === 'product--videostore') {
                  obj.videostore = true;
                  if(value[i].product_variation.sku === 'VThosszabbítás') {

                    this.fooldalService.getSubscriptionForVariationUuid(value[i].product_variation.id).subscribe((response: any) => {
                      const subscription = response.data[0];
                      obj.licenseUuid = subscription.id;
                      obj.cancellable = response.data[0].attributes.scheduled_changes.length === 0;
                      console.warn('setting end date', subscription.attributes.next_renewal);
                      const isoString = response.data[0].attributes.next_renewal;
                      const date = new Date(isoString);
                      obj.end_date = date.getFullYear() + "-" +
                                            String(date.getMonth() + 1).padStart(2, '0') + "-" +
                                            String(date.getDate()).padStart(2, '0') + " " +
                                            String(date.getHours()).padStart(2, '0') + ":" +
                                            String(date.getMinutes()).padStart(2, '0');
                      obj.registered_card = response.data[0].attributes.field_cardmask;
                    });
                  } else {
                    obj.end_date = value[i].expires.substring(0, 10);
                  }
                } else {
                  obj.end_date = value[i].expires.substring(0, 10);
                }
                // Módosított feltétel: Mindig hozzáadja az objektumot, ha az adott elem aktív
                if (value[i].product_variation.product_id?.field_course) {
                  obj.uuid = value[i].product_variation.product_id.field_course.id;
                  obj.title = value[i].product_variation.field_variation_name;
                  obj.link = value[i].product_variation.product_id.field_course.title;
                } else {
                  obj.title = value[i].product_variation.field_variation_name;
                }
                obj.desc = this.htmlconvertService.convertToHtml(value[i].product_variation.field_variation_description?.processed);

                if(!value[i].product_variation.status) {
                  continue;
                }
                this.courseDetailsExpires.push(obj);
              }
            }
          }
        }
    });

    const mainObs = this.loggedUser ? this.fooldalService.allLicensesOfCurrentUser() : of({data:[]});
    mainObs.subscribe((response: any) => {
      const licenses = response.data;
      const ownedCourses = licenses.map((license: any) => license.product_variation.product_id?.field_course?.id);
      const excludedCourses:any[] = [];
      licenses.forEach((license: any) => {
        if(license.state === 'active' && license.product_variation.product_id?.field_excludes_courses && Array.isArray(license.product_variation.product_id.field_excludes_courses)) {
          const ids = license.product_variation.product_id.field_excludes_courses.map((ex:any) => ex.id);
          excludedCourses.push(...ids);
        }
      });
      forkJoin([this.fooldalService.getAllProducts(), this.fooldalService.getAllVideoStoreProducts()]).pipe(
        map(([products, videoStoreProducts]) => [products.data, videoStoreProducts.data]),
        tap(([products, videoStoreProducts]) => {
          products.forEach((product:any) => {
            product.variations.forEach((variation:any) => {
              const license = licenses.find((license: any) => license.product_variation.product_id?.id === product.id);
              const hasActiveLicense = licenses.some((license: any) => license.product_variation.product_id?.id === product.id && license.state === 'active');
              const pastDate = new Date();
              pastDate.setFullYear(pastDate.getFullYear() - 3);
              if(!variation.field_extension) {
                const productPrerequisiteCourseId = product.field_prerequisite?.id;
                if(productPrerequisiteCourseId && !license && ownedCourses.includes(productPrerequisiteCourseId) && !excludedCourses.includes(product.field_course.id)) {
                  this.processProductVariation(variation);
                }
                else if(!productPrerequisiteCourseId && (!license || (license.state === 'expired' && new Date(license.expires) < pastDate)) && !excludedCourses.includes(product.field_course.id)){
                  //this.processProductVariation(variation);
                }

                if(!productPrerequisiteCourseId) {
                  this.processSecondaryProductVariation(variation, hasActiveLicense, excludedCourses.includes(product.field_course.id));
                }
              } else {
                const productPrerequisiteCourseId = product.field_prerequisite?.id;
                if(productPrerequisiteCourseId && ownedCourses.includes(productPrerequisiteCourseId) && !excludedCourses.includes(product.field_course.id)) {
                  if(product.field_prerequisite_required) {
                    this.sharedService.spinner$.next(true);
                    this.fooldalService.enrolledUserOutline(product.field_course.meta.drupal_internal__target_id).subscribe((response: any) => {

                      if(Object.values(response.outline).every((item: any) => item.fulfillment.complete[0] && item.fulfillment.complete[0].value)) {
                        this.processProductVariation(variation);
                      }
                      this.sharedService.spinner$.next(false);
                    });
                  } else {
                    this.processProductVariation(variation);
                  }

                }
                else if(!productPrerequisiteCourseId && license) {

                  if(license.state === 'active' ||  (license.state === 'expired' && new Date(license.expires) > pastDate)) {
                    this.processProductVariation(variation);
                  }
                }
              }
            });
          });

          videoStoreProducts.forEach((product:any) => {
            product.variations.forEach((variation:any) => {
              const license = licenses.find((license: any) => license.product_variation.product_id?.id === product.id);
              const hasActiveLicense = licenses.some((license: any) => license.product_variation.product_id?.id === product.id && license.state === 'active');
              const sameVariation = licenses.find((license:any) => license.product_variation.id === variation.id);
              const pastDate = new Date(new Date().getDate() - 10);
              if(!variation.field_extension) {
                if(!license || (license.state === 'expired' && new Date(license.expires) < pastDate)) {
                  this.processVideoStoreProductVariation(variation, hasActiveLicense);
                }

                this.processSecondaryVideoStoreProductVariation(variation, hasActiveLicense);
              } else  {
                if(license && !sameVariation) {

                  if(license.state === 'active' ||  (license.state === 'expired' && new Date(license.expires) > pastDate)) {
                    this.processVideoStoreProductVariation(variation);
                  }
                }
              }
            });
          });
          this.sharedService.spinner$.next(false);
        })
      ).subscribe();

    });


    //kurzusok kiirása, amikor nincs bejelentkezve
    this.fooldalService.getCourses().subscribe(c => {
      for (const [key, value] of Object.entries(c)) {
        if (key === 'courses') {
          for (let i in value) {
            const objCourse = {
              title: value[i].title[0].value,
              uuidCourse: value[i].uuid[0].value
            };
            this.course.push(objCourse);

          }
        }
      }
    });

    this.fooldalService.getPreviews().subscribe((previewResponse:any) => {
      previewResponse.data.forEach((preview: any) => {
        const variations = preview.variations.map((variation: any) => {
          return {
            link: preview.title,
            title: variation.field_variation_name,
            desc: this.htmlconvertService.convertToHtml(variation.field_variation_description?.processed),
            price: variation.price.formatted,
          }
        });
        this.coursePreviews.push(...variations);
      });
    });
  }

  secondaryProductsForLicens: any[] = [];
  private processSecondaryProductVariation(variation: any, alreadyHasLicense?: boolean, excluded?: boolean) {
    var productsObj = {
      title: '',
      sku: '',
      desc: '' as SafeHtml,isTextOverflow: true,
      list_price: '',
      price: '',
      month: '',
      type: '',
      type_id: '',
      alreadyHasLicense,
      excluded
    }
    productsObj.type = variation.type;
    productsObj.type_id = variation.id;
    productsObj.sku = variation.sku;
    productsObj.desc = this.htmlconvertService.convertToHtml(variation.field_variation_description?.processed);
    if (variation.list_price !== null) {
      productsObj.list_price = variation.list_price.formatted;
    }
    productsObj.price = variation.price.formatted;

    const regex = /(.+?) - (\d+ .*)/;
    const founded = variation.title.match(regex);
    if (founded) {
      const cutTitle = founded[1].trim();
      const cutMonth = founded[2].trim();
      productsObj.title = cutTitle;
      productsObj.month = cutMonth;
    } else {
      const value = variation.license_expiration.target_plugin_configuration.interval.interval;
      const periodName = variation.license_expiration.target_plugin_configuration.interval.period;
      if(periodName === 'month') {
        productsObj.month = value + ' hónap';
      }
      if(periodName === 'day') {
        productsObj.month = value + ' nap';
      }
      if(periodName === 'year') {
        productsObj.month = value + ' év';
      }
      if(periodName === 'week') {
        productsObj.month = value + ' hét';
      }
    }
    productsObj.title = variation.field_variation_name;
    if(!variation.status) {
      return;
    }
    this.secondaryProductsForLicens.push(productsObj);
  }

  private processProductVariation(variation: any) {
    var productsObj = {
      title: '',
      sku: '',
      desc: '' as SafeHtml,isTextOverflow: true,
      list_price: '',
      price: '',
      month: '',
      type: '',
      type_id: '',

    }
    productsObj.type = variation.type;
    productsObj.type_id = variation.id;
    productsObj.sku = variation.sku;
    productsObj.desc = this.htmlconvertService.convertToHtml(variation.field_variation_description?.processed);
    if (variation.list_price !== null) {
      productsObj.list_price = variation.list_price.formatted;
    }
    productsObj.price = variation.price.formatted;

    const regex = /(.+?) - (\d+ .*)/;
    const founded = variation.title.match(regex);
    if (founded) {
      const cutTitle = founded[1].trim();
      const cutMonth = founded[2].trim();
      productsObj.title = cutTitle;
      productsObj.month = cutMonth;
    } else {
      const value = variation.license_expiration.target_plugin_configuration.interval.interval;
      const periodName = variation.license_expiration.target_plugin_configuration.interval.period;
      if(periodName === 'month') {
        productsObj.month = value + ' hónap';
      }
      if(periodName === 'day') {
        productsObj.month = value + ' nap';
      }
      if(periodName === 'year') {
        productsObj.month = value + ' év';
      }
      if(periodName === 'week') {
        productsObj.month = value + ' hét';
      }
    }
    productsObj.title = variation.field_variation_name;
    if(!variation.status) {
      return;
    }
    this.productsForLicens.push(productsObj);
  }

  secondaryProductsVidForLicens: any[] = [];
  private processSecondaryVideoStoreProductVariation(variation: any, alreadyHasLicense?: boolean) {
    if(variation.sku === 'VTFREEF' || variation.sku === 'VTFREE') {
      return;
    }
    var productVidObj = {
      title: '',
      sku: '',
      desc: '' as SafeHtml,isTextOverflow: true,
      list_price: '',
      price: '',
      month: '',
      type: '',
      type_id: '',
      alreadyHasLicense: false
    }
    productVidObj.type = variation.type;
    productVidObj.alreadyHasLicense = !!alreadyHasLicense;
    productVidObj.type_id = variation.id;
    productVidObj.sku = variation.sku;
    productVidObj.desc = this.htmlconvertService.convertToHtml(variation.field_variation_description?.processed);
    if (variation.list_price !== null) {
      productVidObj.list_price = variation.list_price.formatted;
    }
    const split = variation.price.formatted.split(',');
    const priceToConvert = split[0].replace(/\s/g, '');
    const price = Number(variation.price.number);
    const per10 = price * this.percentage;
    const pricePer10minus = price - per10;
    const formattedString = pricePer10minus.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    productVidObj.price = formattedString + " Ft";
    const regex = /(.+?) - (\d+ .*)/;
    const founded = variation.title.match(regex);
    if (founded) {
      const cutTitle = founded[1].trim();
      const cutMonth = founded[2].trim();

      productVidObj.month = cutMonth;
    } else {
      const value = variation.license_expiration.target_plugin_configuration.interval.interval;
      const periodName = variation.license_expiration.target_plugin_configuration.interval.period;
      if(periodName === 'month') {
        productVidObj.month = value + ' hónap';
      }
      if(periodName === 'day') {
        productVidObj.month = value + ' nap';
      }
      if(periodName === 'year') {
        productVidObj.month = value + ' év';
      }
      if(periodName === 'week') {
        productVidObj.month = value + ' hét';
      }
    }
    productVidObj.title = variation.field_variation_name;
    if(!variation.status) {
      return;
    }
    this.secondaryProductsVidForLicens.push(productVidObj);
  };

  private processVideoStoreProductVariation(variation: any, alreadyHasLicense?: boolean) {
    if(variation.sku === 'VTFREEF' || variation.sku === 'VTFREE') {
      return;
    }
    var productVidObj = {
      title: '',
      sku: '',
      desc: '' as SafeHtml,isTextOverflow: true,
      list_price: '',
      price: '',
      month: '',
      type: '',
      type_id: '',
      alreadyHasLicense: false
    }
    productVidObj.type = variation.type;
    productVidObj.alreadyHasLicense = !!alreadyHasLicense;
    productVidObj.type_id = variation.id;
    productVidObj.sku = variation.sku;
    productVidObj.desc = this.htmlconvertService.convertToHtml(variation.field_variation_description?.processed);
    if (variation.list_price !== null) {
      productVidObj.list_price = variation.list_price.formatted;
    }
    const split = variation.price.formatted.split(',');
    const priceToConvert = split[0].replace(/\s/g, '');
    const price = Number(variation.price.number);
    const per10 = price * this.percentage;
    const pricePer10minus = price - per10;
    const formattedString = pricePer10minus.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    productVidObj.price = formattedString + " Ft";
    const regex = /(.+?) - (\d+ .*)/;
    const founded = variation.title.match(regex);
    if (founded) {
      const cutTitle = founded[1].trim();
      const cutMonth = founded[2].trim();

      productVidObj.month = cutMonth;
    } else {
      const value = variation.license_expiration.target_plugin_configuration.interval.interval;
      const periodName = variation.license_expiration.target_plugin_configuration.interval.period;
      if(periodName === 'month') {
        productVidObj.month = value + ' hónap';
      }
      if(periodName === 'day') {
        productVidObj.month = value + ' nap';
      }
      if(periodName === 'year') {
        productVidObj.month = value + ' év';
      }
      if(periodName === 'week') {
        productVidObj.month = value + ' hét';
      }
    }
    productVidObj.title = variation.field_variation_name;
    if(!variation.status) {
      return;
    }
    this.productsVidForLicens.push(productVidObj);
  };

  prev(preview: any) {
    this.router.navigate(['/kurzusok/' + preview.link]);
  }



  ngOnDestroy() {
    if (this.loggedInUserSubscription) {
      this.loggedInUserSubscription.unsubscribe();
    }
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  isTextOverflowing(lesson: any, measureContainer?: HTMLParagraphElement) {
    if(measureContainer) {
      return measureContainer.scrollHeight > 120;
    }
    const textContainer = document.createElement('div');
    textContainer.innerHTML = lesson.desc;
    document.body.appendChild(textContainer);
    const isOverflowing = textContainer.scrollHeight > 90;
    lesson.scrollHeight = textContainer.scrollHeight;
    document.body.removeChild(textContainer);
    return isOverflowing;
  }


  toggleTextOverflow(lesson: any) {
    lesson.isTextOverflow = !lesson.isTextOverflow;
  }

  openModal(platform: string, data?: any) {
    this.modalData = data;
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.style.display = 'block';
      modal.classList.add('show');
    }
    this.showModal = true;
    this.platform = platform;
    this.renderer.addClass(document.body, 'no-scroll');

    // Az adott platformnak megfelelő tartalom beállítása
    this.modalTitle = '';
    this.modalContent = '';
    this.modalCancelTitle = 'Bezárás';
    this.modalActionTitle = '';
    switch (platform) {
      case 'cancel-recurring':
        this.modalTitle = 'Biztos, hogy lemondod a Videótár előfizetésed?';
        this.modalContent = 'Ha igennel válaszolsz, a következő havi terhelés elmarad, már csak addig tudod a Videótárad használni.';
        this.modalActionTitle = 'Igen';
        this.modalCancelTitle = 'Nem';
        break;
    }

    // A popup tartalom és cím beállítása
    const modalTitleElement = document.querySelector('.modal-title');
    const modalBody = document.querySelector('.modal-body');
    if (modalTitleElement && modalBody) {
      modalTitleElement.innerHTML = this.modalTitle;
      modalBody.innerHTML = this.modalContent;
    }
  }

  closeModal() {
    const modal = document.getElementById('exampleModal');
    if (modal) {
      modal.style.display = 'none';
      modal.classList.remove('show');
    }
    this.showModal = false;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  doModalAction() {
    switch (this.platform) {
      case 'cancel-recurring':
        this.cancel(this.modalData);
        break;
    }

  }
}
